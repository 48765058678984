<template>
    <default-layout class="accDashPage">
        <header class="pageHeader">
            <h1 class="pageTitle">
                <i class="fas fa-tachometer-alt"></i> Dashboard
            </h1>
        </header>
        <main v-if="Object.keys(data).length">
            <article class="box isAcross">
                <header
                    class="isSessions"
                    @click="$router.push({ name: 'account.recs' })"
                >
                    <i class="fas fa-play has-text-primary"></i>
                    <h3>Sessions</h3>
                </header>
                <ul>
                    <li>
                        <h4>Live</h4>
                        <p>
                            {{ data.live }}
                        </p>
                    </li>
                    <li>
                        <h4>Today</h4>
                        <p>
                            {{ data.today }}
                        </p>
                    </li>
                    <li>
                        <h4>Yesterday</h4>
                        <p>
                            {{ data.yesterday }}
                        </p>
                    </li>
                    <li>
                        <h4>7 days</h4>
                        <p>
                            {{ data.last7 }}
                        </p>
                    </li>
                    <li>
                        <h4>30 days</h4>
                        <p>
                            {{ data.last30 }}
                        </p>
                    </li>
                    <li>
                        <h4>Total</h4>
                        <p>
                            {{ data.total }}
                        </p>
                    </li>
                </ul>
            </article>
            <div class="tile is-ancestor">
                <div class="tile is-parent">
                    <article class="tile is-child box isAvg">
                        <header>
                            <i class="fas fa-clock has-text-info"></i>
                            <h3>Session Length</h3>
                            <h5>Average In Minutes</h5>
                        </header>
                        <p>{{ data.avgDurationMins }}</p>
                    </article>
                </div>
                <div class="tile is-parent">
                    <article class="tile is-child box isAvg">
                        <header>
                            <i class="fas fa-eye has-text-info"></i>
                            <h3>Page Views</h3>
                            <h5>Average Per Session</h5>
                        </header>
                        <p>{{ data.avgPageViews }}</p>
                    </article>
                </div>
            </div>
            <div class="tile is-ancestor">
                <div class="tile is-parent">
                    <article class="tile is-child box isErrors">
                        <header>
                            <i class="fas fa-bug has-text-danger"></i>
                            <h3>Top Errors</h3>
                            <h5>Last 30 Days</h5>
                        </header>
                        <ul>
                            <li
                                v-for="(item, index) in data.errors"
                                :key="index"
                                @click="
                                    $router.push({
                                        name: 'account.recs',
                                        query: { q: item.name },
                                    })
                                "
                            >
                                <div class="errorMessage">
                                    <b-icon
                                        icon="play-circle"
                                        size="is-large"
                                        class="has-text-primary"
                                    ></b-icon>
                                    {{ item.name }}
                                </div>
                                <span>{{ item.count }}</span>
                            </li>
                        </ul>
                    </article>
                </div>
            </div>
            <div class="tile is-ancestor">
                <div class="tile is-parent">
                    <article class="tile is-child box">
                        <header>
                            <i
                                class="fas fa-plane-arrival has-text-success"
                            ></i>
                            <h3>Top Landing Pages</h3>
                            <h5>Last 30 Days</h5>
                        </header>
                        <ul>
                            <li
                                v-for="(item, index) in data.startPages"
                                :key="index"
                            >
                                <span>{{ item.name }}</span>
                                <span>{{ item.count }}</span>
                            </li>
                        </ul>
                    </article>
                </div>
            </div>
            <div class="tile is-ancestor">
                <div class="tile is-parent">
                    <article class="tile is-child box">
                        <header>
                            <i class="fas fa-door-open has-text-warning"></i>
                            <h3>Top Exit Pages</h3>
                            <h5>Last 30 Days</h5>
                        </header>
                        <ul>
                            <li
                                v-for="(item, index) in data.endPages"
                                :key="index"
                            >
                                <span>{{ item.name }}</span>
                                <span>{{ item.count }}</span>
                            </li>
                        </ul>
                    </article>
                </div>
            </div>
            <div class="tile is-ancestor">
                <div class="tile is-parent">
                    <article class="tile is-child box">
                        <header>
                            <i class="fas fa-globe has-text-primary"></i>
                            <h3>Browsers</h3>
                            <h5>Last 30 Days</h5>
                        </header>
                        <ul>
                            <li
                                v-for="(item, index) in data.browsers"
                                :key="index"
                            >
                                <span>{{ item.name }}</span>
                                <span>{{ item.count }}</span>
                            </li>
                        </ul>
                    </article>
                </div>
                <div class="tile is-parent">
                    <article class="tile is-child box">
                        <header>
                            <i class="fas fa-desktop has-text-primary"></i>
                            <h3>Screen Resolutions</h3>
                            <h5>Last 30 Days</h5>
                        </header>
                        <ul>
                            <li
                                v-for="(item, index) in data.resolutions"
                                :key="index"
                            >
                                <span>{{ item.name }}</span>
                                <span>{{ item.count }}</span>
                            </li>
                        </ul>
                    </article>
                </div>
            </div>
            <div class="tile is-ancestor">
                <div class="tile is-parent">
                    <article class="tile is-child box">
                        <header>
                            <i class="fas fa-map-marker-alt has-text-info"></i>
                            <h3>Locations</h3>
                            <h5>Last 30 Days</h5>
                        </header>
                        <ul>
                            <li
                                v-for="(item, index) in data.locations"
                                :key="index"
                            >
                                <span>{{ item.name }}</span>
                                <span>{{ item.count }}</span>
                            </li>
                        </ul>
                    </article>
                </div>
                <div class="tile is-parent">
                    <article class="tile is-child box">
                        <header>
                            <i class="fas fa-flag has-text-info"></i>
                            <h3>Countries</h3>
                            <h5>Last 30 Days</h5>
                        </header>
                        <ul>
                            <li
                                v-for="(item, index) in data.countries"
                                :key="index"
                            >
                                <span>{{ item.name }}</span>
                                <span>{{ item.count }}</span>
                            </li>
                        </ul>
                    </article>
                </div>
            </div>
        </main>
    </default-layout>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'Dashboard',
    computed: {
        ...mapGetters({ data: 'account/stats' }),
    },
    async created() {
        this.$loading.show()
        const { success, message } = await this.$store.dispatch(
            'account/loadStats'
        )
        this.$loading.hide()
        !success && this.$toast.error(message)
    },
}
</script>
